<div class="card">
  <div class="card-body">
    <!--LISTA CODIGOS PLANOS-->
    <div class="float-left mr-2">
      <div class="form-group">
        <label>{{ 'datosForja.codigoPlano' | translate }}</label>
        <div class="caja">
          <kendo-multiselect (valueChange)="cambioFiltro()" kendoMultiSelectSummaryTag [data]="listaPlanos" [(ngModel)]="planosSeleccionados"
            [textField]="'codigoPlano'" [valueField]="'codigoPlano'"
            placeholder="{{ 'filtroForja.seleccioneCodigoPlano' | translate }}" [autoClose]="false"
            style="width: 295px;" [kendoDropDownFilter]="{operator: 'contains'}">
            <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
              <span class="k-icon k-i-arrow-s"></span>
              <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].codigoPlano}}</ng-container>
              <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{
                'filtroForja.planosSeleccionados' | translate }}</ng-container>
            </ng-template>
            <kendo-multiselect-messages noDataText="{{'grids.noRecords' | translate}}"></kendo-multiselect-messages>
          </kendo-multiselect>
        </div>
      </div>
    </div>

    <!--LISTA FIGURAS-->
    <div class="float-left mr-2">
      <div class="form-group">
        <label>{{ 'datosForja.figura' | translate }}</label>
        <div class="caja">
          <kendo-multiselect (valueChange)="cambioFiltro()" kendoMultiSelectSummaryTag [data]="listaFiguras" [(ngModel)]="figurasSeleccionadas"
            [textField]="'figura'" [valueField]="'figura'" placeholder="{{ 'filtroForja.seleccioneFigura' | translate }}"
            [autoClose]="false" style="width: 295px;" [kendoDropDownFilter]="{operator: 'contains'}">
            <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
              <span class="k-icon k-i-arrow-s"></span>
              <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].figura}}</ng-container>
              <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{
                'filtroForja.figurasSeleccionadas' | translate }}</ng-container>
            </ng-template>
            <kendo-multiselect-messages noDataText="{{'grids.noRecords' | translate}}"></kendo-multiselect-messages>
          </kendo-multiselect>
        </div>
      </div>
    </div>

    <!--LISTA EQUIVALENCIAS P10000-->
    <div class="float-left mr-2">
      <div class="form-group">
        <label>{{ 'datosForja.equivalenciaP10000' | translate }}</label>
        <div class="caja">
          <kendo-multiselect (valueChange)="cambioFiltro()" kendoMultiSelectSummaryTag [data]="listaEquivalenciasP10000"
            [(ngModel)]="equivalenciasP10000Seleccionadas" [textField]="'equivalencia'" [valueField]="'id'"
            placeholder="{{ 'filtroForja.seleccioneEquivalencia' | translate }}" [autoClose]="false"
            style="width: 295px;" [kendoDropDownFilter]="{operator: 'contains'}">
            <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
              <span class="k-icon k-i-arrow-s"></span>
              <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].equivalencia}}</ng-container>
              <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{
                'filtroForja.equivalenciasSeleccionadas' | translate }}</ng-container>
            </ng-template>
            <kendo-multiselect-messages noDataText="{{'grids.noRecords' | translate}}"></kendo-multiselect-messages>
          </kendo-multiselect>
        </div>
      </div>
    </div>

    <!--LISTA EQUIVALENCIAS P5000-->
    <div class="float-left mr-2">
      <div class="form-group">
        <label>{{ 'datosForja.equivalenciaP5000' | translate }}</label>
        <div class="caja">
          <kendo-multiselect (valueChange)="cambioFiltro()" kendoMultiSelectSummaryTag [data]="listaEquivalenciasP5000"
            [(ngModel)]="equivalenciasP5000Seleccionadas" [textField]="'equivalencia'" [valueField]="'id'"
            placeholder="{{ 'filtroForja.seleccioneEquivalencia' | translate }}" [autoClose]="false"
            style="width: 295px;" [kendoDropDownFilter]="{operator: 'contains'}">
            <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
              <span class="k-icon k-i-arrow-s"></span>
              <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].equivalencia}}</ng-container>
              <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{
                'filtroForja.equivalenciasSeleccionadas' | translate }}</ng-container>
            </ng-template>
            <kendo-multiselect-messages noDataText="{{'grids.noRecords' | translate}}"></kendo-multiselect-messages>
          </kendo-multiselect>
        </div>
      </div>
    </div>

    <!--LISTA EQUIVALENCIAS LAMINADOR-->
    <div class="float-left mr-2">
      <div class="form-group">
        <label>{{ 'datosForja.equivalenciaLaminador' | translate }}</label>
        <div class="caja">
          <kendo-multiselect (valueChange)="cambioFiltro()" kendoMultiSelectSummaryTag [data]="listaEquivalenciasLaminador"
            [(ngModel)]="equivalenciasLaminadorSeleccionadas" [textField]="'equivalencia'" [valueField]="'id'"
            placeholder="{{ 'filtroForja.seleccioneEquivalencia' | translate }}" [autoClose]="false"
            style="width: 295px;" [kendoDropDownFilter]="{operator: 'contains'}">
            <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
              <span class="k-icon k-i-arrow-s"></span>
              <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].equivalencia}}</ng-container>
              <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{
                'filtroForja.equivalenciasSeleccionadas' | translate }}</ng-container>
            </ng-template>
            <kendo-multiselect-messages noDataText="{{'grids.noRecords' | translate}}"></kendo-multiselect-messages>
          </kendo-multiselect>
        </div>
      </div>
    </div>

    <div class="caja">
      <button kendoButton (click)="onClickFiltrar()" class="btn mr-1  btn-success btn-sm mr-1 nolabel"> {{ 'botones.filtrar' |
        translate}} </button>
    </div>


  </div>
</div>

<div class="card">
  <div class="card-body">
    <kendo-grid [kendoGridBinding]="dataPlanosFiltrados" [sortable]="true" kendoGridSelectBy="id" [navigable]="true"
      filterable="menu" (cellClick)="cellClick($event)" [selectedKeys]="planosSelected" [selectable]="true">
      <ng-template kendoGridToolbarTemplate position="top">
        <div *ngIf="user.gestionUsuarios==2">
          <button kendoButton (click)="onClickEditar()" class="btn mr-1  btn-success btn-sm mr-1"> {{ 'botones.editar' |
            translate}} </button>
          <button kendoButton (click)="onClickNuevo()" class="btn mr-1  btn-primary btn-sm mr-1"> {{ 'botones.nuevo' |
            translate}}
          </button>
          <button kendoButton (click)="onClickEliminar()" class="btn mr-1  btn-danger btn-sm mr-1"> {{ 'botones.eliminar' | translate}} 
          </button>
        </div>
      </ng-template>
      <kendo-grid-checkbox-column width="5%" showSelectAll="true"></kendo-grid-checkbox-column>
      <kendo-grid-column width="10%" field="codigoPlano" title="{{ 'datosForja.codigoPlano' | translate}}" [style]="{'text-align': 'left'}">
      </kendo-grid-column>
      <kendo-grid-column width="10%" field="figura" title="{{ 'datosForja.figura' | translate}}" [style]="{'text-align': 'left'}"> </kendo-grid-column>
      <kendo-grid-column width="10%" field="equivalenciaP10000" title="{{ 'datosForja.equivalenciaP10000' | translate}}" [style]="{'text-align': 'left'}">
      </kendo-grid-column>
      <kendo-grid-column width="10%" field="equivalenciaP5000" title="{{ 'datosForja.equivalenciaP5000' | translate}}" [style]="{'text-align': 'left'}">
      </kendo-grid-column>
      <kendo-grid-column width="10%" field="equivalenciaLaminador" title="{{ 'datosForja.equivalenciaLaminador' | translate}}" [style]="{'text-align': 'left'}">
      </kendo-grid-column>
      <!-- <kendo-grid-column width="10%" field="dd" title="{{ 'datosForja.imprimir' | translate}}" [style]="{'text-align': 'center'}">
        <ng-template kendoGridCellTemplate let-dataItem>
          <button kendoButton class="btn mr-1  btn-danger btn-sm mr-1"><i class="fa fa-file"></i></button>
        </ng-template>
      </kendo-grid-column> -->
      <kendo-grid-messages filter="{{'grids.filter' | translate}}"
        filterAfterOperator="{{'grids.filterAfterOperator' | translate}}"
        filterAfterOrEqualOperator="{{'grids.filterAfterOrEqualOperator' | translate}}"
        filterAndLogic="{{'grids.filterAndLogic' | translate}}"
        filterBeforeOperator="{{'grids.filterBeforeOperator' | translate}}"
        filterBeforeOrEqualOperator="{{'grids.filterBeforeOrEqualOperator' | translate}}"
        filterBooleanAll="{{'grids.filterBooleanAll' | translate}}"
        filterClearButton="{{'filterClearButton' | translate}}"
        filterContainsOperator="{{'grids.filterContainsOperator' | translate}}"
        filterDateToday="{{'grids.filterDateToday' | translate}}"
        filterDateToggle="{{'grids.filterDateToggle' | translate}}"
        filterEndsWithOperator="{{'grids.filterEndsWithOperator' | translate}}"
        filterEqOperator="{{'grids.filterEqOperator' | translate}}"
        filterFilterButton="{{'grids.filterFilterButton' | translate}}"
        filterGtOperator="{{'grids.filterGtOperator' | translate}}"
        filterGteOperator="{{'grids.filterGteOperator' | translate}}"
        filterIsEmptyOperator="{{'grids.filterIsEmptyOperator' | translate}}"
        filterIsFalse="{{'grids.filterIsFalse' | translate}}"
        filterIsNotEmptyOperator="{{'grids.filterIsNotEmptyOperator' | translate}}"
        filterIsNotNullOperator="{{'grids.filterIsNotNullOperator' | translate}}"
        filterIsNullOperator="{{'grids.filterIsNullOperator' | translate}}"
        filterIsTrue="{{'grids.filterIsTrue' | translate}}" filterLtOperator="{{'grids.filterLtOperator' | translate}}"
        filterLteOperator="{{'grids.filterLteOperator' | translate}}"
        filterNotContainsOperator="{{'grids.filterNotContainsOperator' | translate}}"
        filterNotEqOperator="{{'grids.filterNotEqOperator' | translate}}"
        filterNumericDecrement="{{'grids.filterNumericDecrement' | translate}}"
        filterNumericIncrement="{{'grids.filterNumericIncrement' | translate}}"
        filterOrLogic="{{'grids.filterOrLogic' | translate}}"
        filterStartsWithOperator="{{'grids.filterStartsWithOperator' | translate}}"
        loading="{{'grids.loading' | translate}}" noRecords="{{'grids.noRecords' | translate}}"
        unlock="{{'unlock' | translate}}">
      </kendo-grid-messages>

    </kendo-grid>
  </div>
</div>